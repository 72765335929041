// src/index.js
import React from "react";
import ReactDOM from "react-dom/client"; //updated import
import App from "./App.js"; // Added '.js' extension
import "./index.css";
import "./firebase.js"; // Just importing firebase will ensure all initialization runs

// Load the reCAPTCHA script dynamically
const loadRecaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
  script.async = true;
  document.body.appendChild(script);
};

// Initialize reCAPTCHA
loadRecaptcha();

// Render your main app component using React 18's createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
