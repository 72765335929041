import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBluesky } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="social-icons">
        <a href="https://bsky.app/profile/habsology.bsky.social" aria-label="Blue Sky">
          <FontAwesomeIcon icon={faBluesky} />
        </a>
      </div>
      <p className="copyright">© Copyright 2024 - Habsology</p>
    </footer>
  );
};

export default Footer;
