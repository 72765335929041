import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './Feed.css';
import VideoModal from '../components/VideoModal';
import defaultThumbnail from '../assets/images/HockeyHub.png';

const ITEMS_PER_PAGE = 10;

const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

const Feed = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [contentItems, setContentItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchContent = useCallback(async (isInitial = false) => {
    if ((isInitial && !initialLoading) || (!isInitial && loadingMore)) {
      return;
    }

    try {
      if (isInitial) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }

      const contentRef = collection(db, 'content');
      let q = query(
        contentRef,
        orderBy('publishedAt', 'desc'),
        limit(ITEMS_PER_PAGE)
      );

      if (!isInitial && lastDoc) {
        q = query(
          contentRef,
          orderBy('publishedAt', 'desc'),
          startAfter(lastDoc),
          limit(ITEMS_PER_PAGE)
        );
      }

      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setHasMore(false);
        return;
      }

      const newContent = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        thumbnailUrl: doc.data().thumbnailUrl || defaultThumbnail
      }));

      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      
      if (isInitial) {
        setContentItems(newContent);
      } else {
        setContentItems(prev => [...prev, ...newContent]);
      }
      
      setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
      setError(null);
    } catch (err) {
      console.error('Error fetching content:', err);
      setError('Unable to load content. Please try again later.');
    } finally {
      setLoading(false);
      setLoadingMore(false);
      setInitialLoading(false);
    }
  }, [initialLoading, loadingMore, lastDoc]);

  useEffect(() => {
    fetchContent(true);
  }, [fetchContent]);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || !hasMore || loadingMore) return;
      
      const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
      if (window.scrollY >= scrollableHeight - 1000) {
        fetchContent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, loadingMore, fetchContent]);

  const openVideo = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
    setCurrentVideoUrl('');
  };

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60
    };
    
    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
      const value = Math.floor(diffInSeconds / secondsInUnit);
      if (value >= 1) {
        return `${value} ${unit}${value === 1 ? '' : 's'} ago`;
      }
    }
    
    return 'just now';
  };

  const handleClick = (item) => {
    if (item.type === 'video' && item.videoUrl) {
      openVideo(item.videoUrl);
    } else if (item.url) {
      window.open(item.url, '_blank');
    }
  };

  const renderContentCard = (item) => (
    <div 
      key={item.id}
      className={`feed-card ${item.type}`}
      onClick={() => handleClick(item)}
      role="button"
      tabIndex={0}
    >
      <div className="feed-thumbnail-wrapper">
        {item.type === 'video' && (
          <div className="play-button">
            <div className="play-icon" />
          </div>
        )}
        <img
          src={item.thumbnailUrl}
          alt={item.title}
          className="feed-thumbnail"
          loading="lazy"
          onError={(e) => {
            e.target.src = defaultThumbnail;
          }}
        />
        <div className="source-tag">{item.sourceName}</div>
      </div>
      
      <div className="feed-info">
        <h3 className="feed-title">{decodeHTMLEntities(item.title)}</h3>
        <div className="feed-meta">
          {item.author && (
            <>
              <span className="author">{item.author}</span>
              <span className="separator">•</span>
            </>
          )}
          <span className="publish-date">
            {formatRelativeTime(item.publishedAt)}
          </span>
        </div>
      </div>
    </div>
  );

  if (initialLoading) {
    return (
      <div className="feed-container">
        <div className="loading-spinner">
          <div className="spinner" />
        </div>
      </div>
    );
  }

  if (error && !contentItems.length) {
    return (
      <div className="feed-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="feed-container">
      {isVideoOpen && (
        <VideoModal videoUrl={currentVideoUrl} onClose={closeVideo} />
      )}

      <div className="feed-content">
        <div className="feed-sidebar">
          <div className="ad-placeholder">
            🚧 Site Under Construction 🚧
          </div>
        </div>

        <div className="feed-main">
          {contentItems.map(renderContentCard)}
          {loadingMore && (
            <div className="loading-spinner">
              <div className="spinner" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;