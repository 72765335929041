import React, { useState } from 'react';
import './VideoModal.css';

const VideoModal = ({ videoUrl, onClose }) => {
  const [loadError, setLoadError] = useState(false);

  const handleError = () => {
    setLoadError(true);
    console.error('Failed to load video:', videoUrl);
  };

  return (
    <div className="video-modal">
      <div className="video-modal-overlay" onClick={onClose}></div>
      <div className="video-modal-content">
        <button className="video-modal-close" onClick={onClose} aria-label="Close video modal">✖</button>
        {loadError ? (
          <div className="video-error">
            <p>Failed to load video. <a href={videoUrl} target="_blank" rel="noopener noreferrer">Watch on YouTube</a></p>
          </div>
        ) : (
          <iframe
            src={videoUrl.replace('watch?v=', 'embed/')}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onError={handleError}
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default VideoModal;
